$primarycolor: #1872ae !default; // primary
$secondarycolor: #152440 !default; // secondary
$textcolor: #6c757d !default; // texts
$titlecolor: #1d252c !default; // titles
$subcolor: #253237 !default; // subtitles
$whitecolor: #fff !default;
$lightcolor: #eee !default;
$graycolor: #6c757d !default;
$darkcolor: #253237 !default;
$blackcolor: #000 !default; // black
$redcolor: #df002c !default; // badge and danger
$orangecolor: #f95413 !default; // warning

$borderradius: 5px;
$transition_global_delay: .3s;
$transition_global_ease: ease-in-out;
$easeOutExpo_animation: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutQuint_animation: cubic-bezier(0.23, 1, 0.32, 1);

// Responsive Variables
$big : '(max-width: 1600px)';
$only-big : '(min-width: 1200px) and (max-width: 1599px)';
$desktop : '(max-width: 1199px)';
$only-desktop : '(min-width: 992px) and (max-width: 1199px)';
$tabletLandscape: '(max-width: 991px)';
$tabletPortrait: '(max-width: 767px)';
$only-tablet :'(min-width: 576px) and (max-width: 991px)';
$mobile: '(max-width: 575px)';
$only-mobile :'screen and (max-width: 575px)';

/* Theme colors */
$numacomcolor: #7752ff; // 158, 122, 255
$greencolor: #91bc21;
$grittersuccesscolor: #64bd63;
$grittererrorcolor: #f44336;
$gritterwarningcolor: #ff9800;
$red: #6a51f6 !default;
$red_darker: #3625d3 !default;
$red_lighter: #a7a1f9 !default;

$base-top-gradient-start-color:    #bfd255; // 191, 210, 85
$base-top-gradient-middle-color:    #8eb92a; // 142, 185, 42
$base-top-gradient-end-color:    #72aa00; // 114, 170, 0
$base-top-gradient-start-subcolor:    #76d2f0; // 118, 210, 240
$base-top-gradient-middle-subcolor:    #139fd2; // 19, 159, 210
$base-top-gradient-end-subcolor:    #048db9; // 4, 141, 185
