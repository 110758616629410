body {
  &.open { overflow: hidden; }
  &.size-1 {
    font-size: 14px;
    .panel-title, .btn:not(.btn-icon), .dropdown-menu { font-size: 14px; }
    .gritter-item { font-size: 12px !important; }
  }
  &.size-2 {
    font-size: 15px;
    .panel-title, .btn:not(.btn-icon), .dropdown-menu { font-size: 15px; }
    .gritter-item { font-size: 13px !important; }
  }
  &.size-3 {
    font-size: 16px;
    .panel-title, .btn:not(.btn-icon), .dropdown-menu { font-size: 16px; }
    .gritter-item { font-size: 14px !important; }
  }
  &.size-4 {
    font-size: 17px;
    .panel-title, .btn:not(.btn-icon), .dropdown-menu { font-size: 17px; }
    .gritter-item { font-size: 15px !important; }
  }
}


/* LOGO */
.login-header, .header {
  & .brand {
    & .logo {
      top: 4px !important;
    }
  }
  & .logo, & .navbar-logo {
    float: left; margin: 0; position: relative; width: 60px; height: 28px; background: none; overflow: visible; border: 0; margin-right: 10px;
  }
}


/* HAX */
/*.btn.btn-xs[data-click] { line-height: 18px; padding: 0; }*/


/* HEADER */
@media (max-width: 767px) {
  .header .navbar-nav {
    margin-top: -50px;
  }
}


/* SIDEBAR */
.sidebar {
  & .nav {
    & > li {
      &.nav-header {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 700;
      }
    }
  }
}


/* FORMS */



/* EDIT PICTURE */
figure {
  &.edit-picture {
    cursor: pointer;
    & .wrapper {
      &:after {
        content: ""; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(0,0,0,.5); opacity: 0;
        transition: all $transition_global_delay $easeOutExpo_animation;
      }
      & img {
        width: 100%; height: 100%; border-radius: 50%;
      }
    }
    & button {
      position: absolute; bottom: 10px; right: 10px; opacity: 0;
      transition: all $transition_global_delay $easeOutExpo_animation;
    }
    &:hover {
      & .wrapper {
        &:after {
          opacity: 1;
        }
      }
      & button {
        opacity: 1;
      }
    }
  }
}


/* FILEUPLOADER GALLERY */
.fileuploader-popup-meta {
  & h5 { color: #8e8e8e !important; }
}


/* HEADER */
#header .navbar-header a.navbar-brand small { font-size: 50%; color: rgba(255,255,255,.7); }


/* DATATABLES */
div.dataTables_wrapper div.dataTables_length select { width: 75px !important; } // hax buttons
.dataTables_wrapper {
  & .dt-buttons {
    & a.btn {
      cursor: pointer;
    }
  }
}
table.dataTable {
  & tr {
    transition: all $transition_global_delay $easeOutExpo_animation;
    & td {
      transition: all $transition_global_delay $easeOutExpo_animation;
      &.highlight { background-color: #ccc !important; color: #000; cursor: pointer !important; }
      &.unseen {
        background-color: $red !important; color: #fff;
        &.highlight { background-color: $red_darker !important; color: #fff; }
      }
    }
    & > td {
      & a {
        &.btn[data-action] {
          margin-right: 3px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      & i.material-icons {
        float: left;
        width: 24px;
        height: 24px;
        text-align: center;
        margin: -2px 10px -2px 0;
      }
    }
    &.shown {
      & td {
        background: $numacomcolor;
        color: #fff;
      }
      & ~ tr {
        background: rgba($numacomcolor, .15);
      }
    }
  }
}
.table>tfoot>tr>th, .table tfoot th { color: #000; font-weight: 600; border-top: 1px solid #bdbdbd!important; }
.table-bordered.widget-table>tfoot>tr>th {
  border-bottom: none;
  &:last-child { border-right: none; }
}

//select[name$="table_length"] { background-color: rgba(0,0,0,.7) !important; }


/* REDACTOR */
.redactor-box-fullscreen { z-index: 1052; }


/* GRITTER NOTIFICATIONS */
#gritter-notice-wrapper { z-index: 10000 !important; }
.gritter-item-wrapper {
  &.my-success .gritter-close { border-left: 2px solid $grittersuccesscolor !important; }
  &.my-error .gritter-close { border-left: 2px solid $grittererrorcolor !important; }
  &.my-warning .gritter-close { border-left: 2px solid $gritterwarningcolor !important; }
  & .gritter-close:before, .gritter-light .gritter-close:before { content: 'Fermer'!important; }
}
/*
.gritter-item-wrapper.my-success .gritter-bottom, .gritter-item-wrapper.my-success .gritter-item, .gritter-item-wrapper.my-success .gritter-top { background: rgba($grittersuccesscolor, .7) !important; }
.gritter-item-wrapper.my-error .gritter-bottom, .gritter-item-wrapper.my-error .gritter-item, .gritter-item-wrapper.my-error .gritter-top { background: rgba($grittererrorcolor, .7) !important; }
.gritter-item-wrapper.my-warning .gritter-bottom, .gritter-item-wrapper.my-warning .gritter-item, .gritter-item-wrapper.my-warning .gritter-top { background: rgba($gritterwarningcolor, .7) !important; }
*/


/* SUCCESS */
.check_mark { width: 80px; height: 130px; position: absolute; top: 50%; left: 50%; margin: -40px 0 0 -65px; }
.sa-icon { width: 80px; height: 80px; border: 4px solid gray;border-radius: 40px; border-radius: 50%; margin: 20px auto;
  padding: 0; position: relative; box-sizing: content-box; }
.sa-icon.sa-success { border-color: #4CAF50; }
.sa-icon.sa-success::before, .sa-icon.sa-success::after { content: ''; border-radius: 40px; border-radius: 50%; position: absolute; width: 60px; height: 120px; background: transparent; transform: rotate(45deg); }
.sa-icon.sa-success::before { border-radius: 120px 0 0 120px; top: -7px; left: -33px; transform: rotate(-45deg); transform-origin: 60px 60px; }
.sa-icon.sa-success::after { border-radius: 0 120px 120px 0; top: -11px; left: 30px; transform: rotate(-45deg); transform-origin: 0px 60px; }
.sa-icon.sa-success .sa-placeholder { width: 80px; height: 80px; border: 4px solid rgba(76, 175, 80, .5); border-radius: 40px; border-radius: 50%; box-sizing: content-box; position: absolute; left: -4px; top: -4px; z-index: 2; }
.sa-icon.sa-success .sa-fix { width: 5px; height: 90px; background-color: transparent; position: absolute; left: 28px; top: 8px; z-index: 1; transform: rotate(-45deg); }
.sa-icon.sa-success.animate::after { animation: rotatePlaceholder 4.25s ease-in; }
.sa-icon.sa-success { border-color: transparent\9; }
.sa-icon.sa-success .sa-line.sa-tip { -ms-transform: rotate(45deg) \9; }
.sa-icon.sa-success .sa-line.sa-long { -ms-transform: rotate(-45deg) \9; }
.animateSuccessTip { animation: animateSuccessTip 0.75s; }
.animateSuccessLong { animation: animateSuccessLong 0.75s; }
@-webkit-keyframes animateSuccessLong {
  0% { width: 0; right: 46px; top: 54px; }
  65% { width: 0; right: 46px; top: 54px; }
  84% { width: 55px; right: 0px; top: 35px; }
  100% { width: 47px; right: 8px; top: 38px; }
}
@-webkit-keyframes animateSuccessTip {
  0% { width: 0; left: 1px; top: 19px; }
  54% { width: 0; left: 1px; top: 19px; }
  70% { width: 50px; left: -8px; top: 37px; }
  84% { width: 17px; left: 21px; top: 48px; }
  100% { width: 25px; left: 14px; top: 45px; }
}
@keyframes animateSuccessTip {
  0% { width: 0; left: 1px; top: 19px; }
  54% { width: 0; left: 1px; top: 19px; }
  70% { width: 50px; left: -8px; top: 37px; }
  84% { width: 17px; left: 21px; top: 48px; }
  100% { width: 25px; left: 14px; top: 45px; }
}
@keyframes animateSuccessLong {
  0% { width: 0; right: 46px; top: 54px; }
  65% { width: 0; right: 46px; top: 54px; }
  84% { width: 55px; right: 0px; top: 35px; }
  100% { width: 47px; right: 8px; top: 38px; }
}
.sa-icon.sa-success .sa-line { height: 5px; background-color: #4CAF50; display: block; border-radius: 2px; position: absolute; z-index: 2; }
.sa-icon.sa-success .sa-line.sa-tip { width: 25px; left: 14px; top: 46px; transform: rotate(45deg); }
.sa-icon.sa-success .sa-line.sa-long { width: 47px; right: 8px; top: 38px; transform: rotate(-45deg); }
@-webkit-keyframes rotatePlaceholder {
  0% { transform: rotate(-45deg); }
  5% { transform: rotate(-45deg); }
  12% { transform: rotate(-405deg); }
  100% { transform: rotate(-405deg); }
}
@keyframes rotatePlaceholder {
  0% { transform: rotate(-45deg); }
  5% { transform: rotate(-45deg); }
  12% { transform: rotate(-405deg); }
  100% { transform: rotate(-405deg); }
}
