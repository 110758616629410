/* Theme - Manager */
@import '_variables';


.navbar-logo, .coming-soon .brand .logo, .login .login-header .brand .logo { border-color: $red_lighter $red $red_darker; }
.sidebar .nav > li.active > a,
.sidebar .nav > li.active > a:focus,
.sidebar .nav > li.active > a:hover,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.label.label-theme,
.btn.btn-scroll-to-top, .btn.btn-scroll-to-top:active {
  background: $red !important;
}
.navbar.navbar-inverse .navbar-brand { color: #fff !important; }
.text-theme, .sidebar .sub-menu > li.active > a:before { color: $red; }
@media (max-width: 767px) {
  .navbar-inverse .navbar-header { background: $red; }
}
.gradient-enabled .navbar.navbar-inverse .navbar-brand,
.gradient-enabled .sidebar .nav > li.active > a,
.gradient-enabled .sidebar .nav > li.active > a:focus,
.gradient-enabled .sidebar .nav > li.active > a:hover,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.gradient-enabled .label.label-theme,
.gradient-enabled .btn.btn-scroll-to-top,
.gradient-enabled .top-menu .nav > li.active > a,
.gradient-enabled .top-menu .nav > li.active > a:focus,
.gradient-enabled .top-menu .nav > li.active > a:hover {
  background: $red_lighter !important; /* Old browsers */
  background: -moz-linear-gradient(top,  $red 0%, $red_darker 100%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  $red 0%,$red_darker 100%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  $red 0%,$red_darker 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$red', endColorstr='$red_darker',GradientType=0 ) !important; /* IE6-9 */
}
.pace-progress { background: $red; }
.pace .pace-activity { border-top-color: $red; border-left-color: $red; }
.page-with-light-sidebar .sidebar .nav > li.active > a > i { color: $red; }
.page-with-light-sidebar .sidebar .nav > li.active > a .badge,
.page-with-light-sidebar .sidebar .nav > li.active > a .label.label-theme {
  background: $red;
}
.top-menu .nav > li.active > a,
.top-menu .nav > li.active > a:focus,
.top-menu .nav > li.active > a:hover {
  background: $red;
}
.spinner-small { border-top-color: $red !important; }
