/* Variable */

$bg_body: #eee !default;

$silver_lighter: #FAFAFA !default;
$silver: #EEEEEE !default;
$silver_darker: #BDBDBD !default;

$black: #212121 !default;
$black_darker: #000 !default;
$black_lighter: #212121 !default;

$red: #F44336 !default;
$red_darker: #D32F2F !default;
$red_lighter: #E57373 !default;

$orange: #FF9800 !default;
$orange_darker: #F57C00 !default;
$orange_lighter: #FFB74D !default;

$yellow: #FFEB3B !default;
$yellow_darker: #FBC02D !default;
$yellow_lighter: #FFF176 !default;

$green: #8BC34A !default;
$green_darker: #689F38 !default;
$green_lighter: #AED581 !default;

$aqua: #00BCD4 !default;
$aqua_darker: #0097A7 !default;
$aqua_lighter: #4DD0E1 !default;

//$blue: #2196F3 !default;
//$blue_darker: #1976D2 !default;
//$blue_lighter: #64B5F6 !default;
$blue: #016fd6 !default;
$blue_darker: #173752 !default;
$blue_lighter: #3999ff !default;

$purple: #9C27B0 !default;
$purple_darker: #7B1FA2 !default;
$purple_lighter: #BA68C8 !default;

$grey: #9E9E9E !default;
$grey_darker: #616161 !default;
$grey_lighter: #E0E0E0 !default;

$yellow: #FFEB3B !default;
$yellow_darker: #FBC02D !default;
$yellow_lighter: #FFF176 !default;

$lime: #CDDC39 !default;
$lime_darker: #AFB42B !default;
$lime_lighter: #DCE775 !default;

$teal: #009688 !default;
$teal_darker: #00796B !default;
$teal_lighter: #4DB6AC !default;

$indigo: #3F51B5 !default;
$indigo_darker: #303F9F !default;
$indigo_lighter: #7986CB !default;

$pink: #E91E63 !default;
$pink_darker: #C2185B !default;
$pink_lighter: #F06292 !default;

$white: #ffffff !default;

// Text Color
$body_text_color: #616161 !default;
$heading_text_color: #212121 !default;
$heading_small_text_color: #777 !default;
$form_label_color: #212121 !default;

// Font Setting
$body_font_size: 13px !default;
$body_font_family: 'Roboto', "Helvetica Neue",Helvetica,Arial,sans-serif !default;
$ie8_body_font_family: Arial,sans-serif !default;
