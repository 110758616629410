/* FORM CONTROL */
.form-control {
  &.error {
    text-align: inherit;
  }
}
.form-control.error, .form-check-label input.error ~ .checkmark, .form-control.error ~ span.select2 {
  border-color: $redcolor !important; border-style: solid; border-width: 2px; color: $redcolor;
}
.radio.radio-css input.form-control.error ~ label, .checkbox.checkbox-css input.form-control.error ~ label, .form-check-input.error ~ label, .custom-control-input.error  ~ label {
  color: $redcolor;
  &::before { background: $redcolor; }
}
.switcher > input[type="checkbox"].error ~ label {
  &::before { color: $redcolor; background: $redcolor; }
}
.custom-control-input ~ div.error, .switcher > input[type="checkbox"].error ~ div.error, .form-control.error ~ div.error, .form-check-input.error ~ div.error { display: none !important; }


/* SELECT 2 */
span.select2 { border-radius: .25rem; width: 100% !important; }
.select2-container--default .select2-selection--multiple, .select2-container .select2-selection--single {
  //border: 1px solid transparent !important;
  border: 1px solid #ccc !important;
  //background: hsla(0,0%,100%,.25) !important;
}


/* X-EDITABLE */
.editable-container {
  .has-error {
    .form-control {
      border-color: $redcolor !important; border-style: solid; border-width: 2px; color: $redcolor;
    }
  }
  .editable-error-block {
    color: $redcolor; margin: 0px 5px;
  }
}


/* SWITCHERY */
.dataTable {
  .switchery {
    width: 40px; height: 20px;
    & >small {
      height: 20px; width: 20px;
    }
  }
}


/* CROPPER IMAGE RESIZE & CROP */
.cropper-parent {
  height: 0; padding-bottom: 75%; /* 4:3 */ position: relative;
}
.cropper-wrapper, .cropper-preview {
  position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(#fff, .75); box-shadow: inset 0 0 5px rgba(0,0,0,.25); border-radius: .25rem; overflow: hidden;
  & img {
    width: 100%; display: block; height: auto; max-width: 100%;
  }
  &.error {
    //box-shadow: inset 0 0 5px rgba($redcolor, 0.75); /*background-color: rgba($redcolor, .25);*/ border-color: rgba($redcolor, .1) rgba($redcolor, .2) rgba($redcolor, .9) !important;
    border-color: $redcolor !important; border-style: solid; border-width: 2px !important; color: $redcolor;
  }
}
.cropper-upload {
  //overflow: hidden;
  & input { display: none; }
  & label { display: block; float: left; clear: left; width: auto; cursor: pointer; }
}


/* REDACTOR PRO 3 */
.redactor-box {
  &.redactor-styles-on {
    color: #000; font-size: 15px;
  }
  & .redactor-in {
    & strong {
      color: $numacomcolor; font-weight: 900;
    }
  }
  &.error {
    text-align: inherit !important;
    //box-shadow: inset 0 0 5px rgba($redcolor, 0.75) !important; /*background-color: rgba($redcolor, .25) !important;*/
    border-color: $redcolor !important; border-style: solid; border-width: 2px !important; color: $redcolor;
    & .redactor-in p {
      color: $redcolor !important;
    }
  }
}
